import { PageProps } from 'gatsby';
import { TrackingData } from 'packages/innedit';
import React, { FC } from 'react';

import CMSView from '~/components/View';
import Form from '~/containers/Espace/Form';
import params from '~/params/tracking.json';
import TemplateEspace from '~/templates/espace';
import requireEspace, { EspaceProps } from '~/utils/requireEspace';
import { UserProps } from '~/utils/requireUser';

const PageEspaceTaskTrackingCreate: FC<PageProps & EspaceProps & UserProps> =
  function ({ espace, user }) {
    const model = new TrackingData({ espace, params, espaceId: espace.id });

    return (
      <TemplateEspace espace={espace} user={user}>
        <CMSView>
          <Form docId={model.getNewDocId()} model={model} type="create" />
        </CMSView>
      </TemplateEspace>
    );
  };

export default requireEspace(PageEspaceTaskTrackingCreate);
